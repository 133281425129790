#
# Meeting Engine core JS
#

# import Rails from '@rails/ujs'
# import { createApp } from 'vue/dist/vue.esm-bundler'
# import { createStore, mapState } from 'vuex/dist/vuex.esm-bundler'
# import Toast from 'libs/toast'

$.me = $.me || {}
$.extend true, $.me, {
  #----------------------------#
  # Execute a function by name #
  #----------------------------#
  executeByName: (name, context=@, args...) ->
    namespaces = name.split '.'
    fn         = namespaces.pop()

    try
      context = context[namespace] for namespace in namespaces
      throw(false) unless $.isFunction context[fn]
    catch e
      return false

    context[fn].apply context, args

  #-----------------------------#
  # Execute the action callback #
  #-----------------------------#
  actionCallback: (controller, action) ->
    $.me.executeByName "#{controller}.#{action}_act" if controller && action

  #---------------------------------------------#
  # Execute the action callback from xhr object #
  #---------------------------------------------#
  # actionCallbackXhr: (xhr) -> @actionCallback xhr.getResponseHeader('X-Controller'), xhr.getResponseHeader('X-Action')

  # intersectionObserver: (callback, observe, options = {}) ->
  #   return unless IntersectionObserver?
  #   vpObserver = new IntersectionObserver callback, options
  #   vpObserver.observe observe

  #   $(document).one 'turbo:before-visit', ->
  #     vpObserver.disconnect()

  # scrollIntoView: ($elmt, inWindow = true) ->
  #   return unless MutationObserver?

  #   position = $($elmt).position()
  #   if position && position.top > $('body').height()
  #     observer = new MutationObserver ->
  #       if inWindow
  #         window.scrollTo
  #           top: position.top,
  #           left: 0,
  #           behavior: 'smooth'
  #       else
  #         $($elmt).get(0).scrollIntoView
  #           behavior: 'smooth'

  #       observer.disconnect()
  #       return
  #     observer.observe $('body').get(0),
  #       childList: true
  #     return

  #---------#
  # Storage #
  #---------#
  # TODO : Useless, to be Removed
  # storage:
  #   set: (key, value) -> sessionStorage.setItem key, JSON.stringify(value)
  #   get: (key) -> JSON.parse sessionStorage.getItem(key)
  #   remove: (key) -> sessionStorage.removeItem(key)
  #   clear: () -> sessionStorage.clear()
  #   keyExists: (key) -> sessionStorage.getItem(key) isnt null

  jsonUtils:
    dateTimeFormat: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?[Z\+]{1}([\d\:]{5})?$/

    reviver: (key, value) ->
      if typeof value is 'string' && $.me.jsonUtils.dateTimeFormat.test(value)
        return new Date(value)
      else
        return value

  #---------------------------------------------------------------------------#
  # Extend UJS : update DOM on ajax:success with the following actions :      #
  # update, prepend, append, replace, remove                                  #
  # A selector should be provided in the data attribute                       #
  # A replace action without selector will replace the whole template         #
  #---------------------------------------------------------------------------#
  # ujsExtended:
  #   init: ->
  #     $(document).on 'ajax:success', '[data-update],[data-prepend],[data-append],[data-replace],[data-remove],[data-multi-renderer]', (e) =>
  #       [data, status, xhr] = e.detail if e.detail

  #       if $.isPlainObject(data) and elmts = $(e.target).data('multi-renderer')
  #         $.each elmts, (verb, selectors) =>
  #           $.each selectors.split(','), (index, selector) =>
  #             $elmt = $(selector)
  #             if $elmt?
  #               if verb == 'update'        then $elmt.html data[selector.trim()]
  #               else if verb == 'prepend'  then $elmt.prepend data[selector.trim()]
  #               else if verb == 'append'   then $elmt.append data[selector.trim()]
  #               else if verb == 'replace'  then $elmt = $(data[selector.trim()]).replaceAll($elmt)

  #               @_scroll_and_trigger e, $elmt
  #       else if xhr
  #         unless xhr.responseText and typeof xhr.responseText is 'string' and xhr.responseText.match(/^Turbo/)
  #           if elmt = $(e.target).data 'remove'
  #             $(elmt).trigger('me:domRemove').remove()
  #           else
  #             if (elmt = $(e.target).data 'update')?
  #               if elmt
  #                 $(elmt).html xhr.responseText
  #               else
  #                 elmt = '[data-main-container]'
  #                 $(elmt).html xhr.responseText
  #                 window.scrollTo 0,0
  #                 document.getElementById('container')?.scrollTo 0,0 # TODO : Cleanup this shitty mobile fix
  #                 $.me.actionCallbackXhr xhr
  #             else if elmt = $(e.target).data 'prepend' then $(elmt).prepend xhr.responseText
  #             else if elmt = $(e.target).data 'append'  then $(elmt).append xhr.responseText
  #             else if elmt = $(e.target).data 'replace' then elmt = $(xhr.responseText).replaceAll(elmt)

  #             @_scroll_and_trigger e, elmt

  #   _scroll_and_trigger: (e, elmt) ->
  #     if elmt
  #       if $(e.target).is('[data-scroll-to-elmt]') && $(e.target).data('scroll-to-elmt') != ''
  #         scroll_to_elmt = $($(e.target).data('scroll-to-elmt')).get(0)
  #       else if $(e.target).is('[data-scroll-to-elmt]')
  #         scroll_to_elmt = $(elmt).get(0)

  #       e.target.reset() if e.target.dataset.formReset

  #       scroll_to_elmt.scrollIntoView({behavior: 'smooth', block: 'start'}) if scroll_to_elmt
  #       $(elmt).trigger 'me:domChanged', elmt

  #---------------------------------#
  # Ajax file upload using FormData #
  #---------------------------------#
  # multipart:
  #   init: ->
  #     $(document).on 'submit', 'form[data-multipart]', (e) ->
  #       f = $(e.target)
  #       $.ajax({
  #         url: f.attr('action'), data: new FormData(f.get(0)), cache: false, contentType : false, processData : false, type: 'POST',
  #         beforeSend: (jqXHR, settings) ->
  #           if Rails.fire(f.get(0), 'ajax:beforeSend', [jqXHR])
  #             $(document).on 'click.lock', (e) ->
  #               new Toast(I18n.t('js.upload.lock'), { type: 'alert' }).show()
  #               e.preventDefault()

  #             f.find('button').prop 'disabled', true
  #           else
  #             false
  #         ,
  #         complete: (data, status, xhr) ->
  #           $(document).off 'click.lock'
  #           f.find('button').prop 'disabled', false
  #           f.trigger 'ajax:complete', [ data, status, xhr ]
  #           $.me.restricted(data) if data.status is 402
  #         ,
  #         success: (data, status, xhr) ->
  #           $(document).off 'click.lock'
  #           f.trigger 'ajax:success', [ data, status, xhr ]
  #         ,
  #         xhr: ->
  #           xhr = $.ajaxSettings.xhr()
  #           xhr.upload.addEventListener 'progress', (e) ->
  #             f.trigger 'me:upload:progress', [ e.loaded, e.total ]
  #           , false
  #           xhr
  #       })
  #       false

  #--------------#
  # Init objects #
  #--------------#
  init: (objects...) ->
    try
      @[object].init() for object in objects
    catch e

  #---------------------#
  # Generic queue class #
  #---------------------#
  # Queue: class
  #   constructor: (@_data = []) ->
  #   isEmpty:       => @_data.length == 0
  #   enqueue: (obj) => @_data.push obj
  #   shift:         => @_data.shift()
  #   clear:         => @_data = []
  #   size:          => @_data.length

  #------------#
  # Vuex store #
  #------------#
  # store:
  #   init: ->
  #     $.me.store = createStore()

  #---------------#
  # Flash message #
  #---------------#
  # flashMessage:
  #   init: ->
  #     @reset()
  #     $(document).on 'ajax:success', (e) =>
  #       return unless e.detail

  #       xhr  = e.detail[2]
  #       data = xhr.responseText
  #       unless data && typeof data is 'string' && data.match(/^Turbo/)
  #         @_createFromXhr(xhr)
  #     $(document).on 'ajax:error', (e) => @_createFromXhr(e.detail[2])
  #     $(document).on 'click', '[data-flash-message]', (e) =>
  #       return unless @_timer
  #       clearTimeout(@_timer)
  #       @_hide()

  #   reset: ->
  #     @_queue      = new $.me.Queue
  #     @_displaying = false
  #     @

  #   create: (message, type='notice') ->
  #     if @_displaying
  #       @_queue.enqueue arguments
  #     else
  #       @_node().off()
  #       @_node().toggleClass 'message-flash--alert', type is 'alert'
  #       @_message().text message
  #       @_show()

  #   createFromDom: ->
  #     @_show() if !@_displaying && @_message().text()

  #   _createFromXhr: (xhr) ->
  #     if xhr.getResponseHeader('X-Message')
  #       @create decodeURIComponent(escape(xhr.getResponseHeader('X-Message'))), xhr.getResponseHeader('X-Message-Type')

  #   _node: -> $ '[data-flash-message]'

  #   _message: -> @_node().find 'span'

  #   _show: ->
  #     @_displaying = true
  #     @_node().forceDomRendering().addClass('is-show')
  #     @_timer = setTimeout =>
  #       @_hide()
  #     , 3000

  #   _hide: ->
  #     @_timer = null
  #     @_node().removeClass 'is-show'
  #     @_node().one 'transitionend', => @_onHide()

  #   _onHide: ->
  #     @_node().removeClass 'message-flash--alert'
  #     @_message().text ''
  #     @_displaying = false
  #     @create.apply @, @_queue.shift() unless @_queue.isEmpty()

  #------------------#
  # Sticker          #
  #------------------#
  #   sticker:
  #     _$window:  $(window),
  #     _$html:    $('html'),
  # #    _trigger: 'is-sticky-subscribe'

  #     init: ->
  # #      @_$window.scroll =>
  # #        @_setSticky @_$window, @_$html, @_getOffset(@_$window), @_trigger

  #       @_$window.resize =>
  #         offset = @_getOffset @_$window
  # #        @_setSticky @_$window, @_$html, @_getOffset(@_$window), @_trigger

  #     _getOffset: (viewport) ->
  #       value = if viewport.outerWidth() >= 768 then 600 else 480
  #       return value

  #    _setSticky: (viewport, element, offset, trigger) ->
  #      if viewport.scrollTop() >= @_getOffset(@_$window)
  #        if !element.hasClass(trigger)
  #          element.addClass trigger
  #      else
  #        element.removeClass trigger

  #---------#
  # Config  #
  #---------#
  # config:
  #   init: ->
  #     @store = $.me.store
  #     @store.registerModule 'config', {
  #       state: -> {
  #         currentUser: null,
  #         location: null,
  #         device: 0
  #       },
  #       mutations: {
  #         loadCurrentUser: (state, currentUser) -> state.currentUser = currentUser
  #         loadLocation: (state, location) -> state.location = location
  #         loadDevice: (state, device) -> state.device = device
  #       },
  #       actions: {
  #         loadCurrentUser: (context, currentUser) -> context.commit('loadCurrentUser', currentUser)
  #         loadLocation: (context, location) -> context.commit('loadLocation', location)
  #         loadDevice: (context, device) -> context.commit('loadDevice', device)
  #       },
  #       namespaced: true
  #     }
  #     @_load()

  #   _load: ->
  #     # Load config
  #     $.getJSON '/app_config.json', (data) =>
  #       @store.dispatch 'config/loadCurrentUser', data.config.current_user
  #       @store.dispatch 'config/loadLocation', data.config.geolocation
  #       @store.dispatch 'config/loadDevice', data.config.device
  #       $(document).trigger 'me:configLoaded', data

  #------------------#
  # Notification bar #
  #------------------#
  # notifications:
  #   vues: []

  #   init: ->
  #     @store = $.me.store
  #     @store.registerModule 'notifications', {
  #       state: -> {
  #         chatMessages: 0
  #         pmessages: 0
  #         kisses: 0
  #         formViews: 0
  #         friendships: 0
  #         testimonials: 0
  #         socialNotifications: 0
  #         events: 0
  #         chat:
  #           isOpened: false
  #           call:
  #             enabled: false
  #             kind: null
  #       },
  #       mutations: {
  #         set: (state, payload) -> state[payload.field] = payload.value
  #         increment: (state, field) -> state[field] += 1
  #         decrement: (state, field) -> state[field] -= 1
  #         setChatOpen: (state, isOpened) -> state.chat.isOpened = isOpened
  #         setCall: (state, payload) ->
  #           state.chat.call.enabled = payload.enabled
  #           state.chat.call.kind =  payload.kind if payload?.kind
  #       },
  #       actions: {
  #         set: (context, payload) -> context.commit('set', payload)
  #         increment: (context, field) -> context.commit('increment', field)
  #         decrement: (context, field) -> context.commit('decrement', field)
  #         setChatOpen: (context, isOpened) -> context.commit('setChatOpen', isOpened)
  #         setCall: (context, payload) -> context.commit('setCall', payload)
  #       },
  #       namespaced: true
  #     }

  #     @initBars()

  #     $(document).on 'turbo:load', => @initBars() # Redo this shit
  #     $(document).on 'me:configLoaded', (e, data) => @_loadStats(data.stats)

  #   initBars: () ->
  #     @vues.concat $('[data-notification-bar]').map (i, n) =>
  #       delete n.dataset.notificationBar
  #       createApp({
  #         name: "NotificationBar#{i + @vues.length + 1}",
  #         computed: $.extend(
  #           mapState('notifications', ['chatMessages', 'pmessages', 'kisses', 'formViews', 'friendships', 'testimonials', 'socialNotifications', 'events', 'chat'])
  #           {
  #             requests: -> @friendships + @testimonials,
  #             all: -> @kisses + @formViews + @friendships + @testimonials + @events + @socialNotifications,
  #           }
  #         ),
  #         methods: {
  #           openChat: (e) ->
  #             return if @chatMessages is 0
  #             e.preventDefault()
  #             $.me.chat.selectConversationIfExists()

  #           toggleChat: -> $.me.chat.toggle()

  #           hideChat: -> $.me.chat.hide()

  #           format: (value, max=100) ->
  #             return '' unless value > 0
  #             return "#{max}+" if value > max
  #             value.toString()
  #         }
  #       }).use(@store).mount(n)

  #   _loadStats: (stats) ->
  #     @store.dispatch 'notifications/set', { field: 'pmessages', value: stats.new_pmessages_count }
  #     @store.dispatch 'notifications/set', { field: 'kisses', value: stats.new_kisses_count }
  #     @store.dispatch 'notifications/set', { field: 'formViews', value: stats.new_form_views_count }
  #     @store.dispatch 'notifications/set', { field: 'friendships', value: stats.new_friends_count }
  #     @store.dispatch 'notifications/set', { field: 'testimonials', value: stats.new_testimonials_count }
  #     @store.dispatch 'notifications/set', { field: 'socialNotifications', value: stats.new_social_notifications_count }
  #     @store.dispatch 'notifications/set', { field: 'events', value: stats.events_count }

  #----------------#
  # Trophy         #
  #----------------#
  # trophy:
  #   init: ->
  #     return unless document.querySelector('#trophy')
  #     @vue = createApp({
  #       name: 'trophy',
  #       data: -> {
  #         trophy: 0,
  #         points: 0,
  #         _config: null
  #       },
  #       computed: {
  #         trophyName: -> @_config.stages[@trophy].name if @trophy > 0 && @_config?
  #         formatedPoints: ->
  #           fp = new Intl.NumberFormat().format(@points) if Intl? && Intl.NumberFormat?
  #           I18n.t 'js.trophy.points', { count: @points, points: fp or @points }
  #       },
  #       watch: {
  #         points: (n, o) ->
  #           return unless @_config.stages[@trophy + 1]? && n >= @_config.stages[@trophy + 1].points
  #           $.ajax(url: '/pages/trophy', method: 'put').done (data) =>
  #             if data.updated
  #               @trophy = data.trophy
  #             else
  #               @points = data.points
  #       }
  #     }).mount('#trophy')

  #     $(document).on 'me:notification', (e, args) => @_updatePoints(args.type)
  #     $(document).on 'me:configLoaded', (e, data) => @_load(data)

  #   _updatePoints: (type) ->
  #     return unless @vue._config?
  #     np = @vue._config.reckoner[type]

  #     return unless np?
  #     @vue.points += np

  #   _load: (data) ->
  #     @vue.trophy = data.stats.trophy
  #     @vue.points = data.stats.trophy_points
  #     @vue._config = data.config.trophy

  #--------------------#
  # Sentences          #
  #--------------------#
  # sentences:
  #   init: ->
  #     @store = $.me.store
  #     @store.registerModule 'sentences', {
  #       state: -> {
  #         list: []
  #       },
  #       mutations: {
  #         load: (state, sentences) -> state.list = sentences
  #       },
  #       actions: {
  #         load: (context, sentences) -> context.commit('load', sentences)
  #       },
  #       namespaced: true
  #     }

  #     $(document).on 'me:configLoaded', (e, data) => @_load(data.config.sentences)

  #   _load: (sentences) -> @store.dispatch 'sentences/load', sentences

  #--------------------#
  # Emoji              #
  #--------------------#
  emoji:
    library:
      '😊': ":[\\\)D]{1}"
      '😉': ";[\\\)D]{1}"
      '😂': ":'[\\\)D]{1}"
      '😝': ":p"
      '😍': "\\\<3"
      '😲': ":o"
      '😖': ":\\\|"
      '😞': ":\\\("
      '😢': ":'\\\("
      '😸': "\\\^{2}"

    init: ->
      @library[k] = new RegExp "(^|\\s)(#{e})($|\\s)", 'gi' for k, e of @library
      $(document).on 'keyup', 'textarea[data-emoticonize]', (e) => @_emoticonizeInput(e.currentTarget)

    emoticonize: (text) ->
      text = text.replace(e, "$1#{k}$3") for k, e of @library
      text

    _emoticonizeInput: (node) ->
      text       = node.value
      curPos     = node.selectionStart
      curLength  = text.length
      text       = @emoticonize(text)
      node.value = text
      newPos     = curPos + text.length - curLength
      node.setSelectionRange(newPos, newPos)

  #--------------------#
  # Field char counter #
  #--------------------#
  # fieldCharCounter:
  #   _data_attr: 'char-counter'
  #   _selector: '[data-char-counter]'

  #   init: ->
  #     $(document).on 'keyup', @_selector, (e) =>
  #       @_count($(e.target))
  #       return true

  #     $(document).on 'me:domChanged', (e) =>
  #       $(@_selector).each (i, elmt) =>
  #         @_count($(elmt))
  #         return true

  #   _count: (target) ->
  #     $(target.data(@_data_attr)).text target.val().length

  #----------#
  # Dropdown #
  #----------#
  # dropdown:
  #   init: ->
  #     $(document).on 'click', '[data-dropdown-trigger]', (e) => @_toggle($(e.currentTarget))

  #   _toggle: (target) ->
  #     elmt = if target.attr('data-dropdown-trigger') isnt ''
  #       $(target.attr('data-dropdown-trigger'))
  #     else
  #       target.closest('[data-dropdown]')

  #     isOpen = elmt.hasClass 'is-open'
  #     if isOpen
  #       elmt.removeClass 'is-open'
  #     else
  #       @_closeAll()
  #       elmt.addClass 'is-open'
  #       $('body').on 'click.me.dropdown', (e) =>
  #         return if $(e.target).is('[data-dropdown-panel]') || $(e.target).closest('[data-dropdown-trigger]').length > 0
  #         @_closeAll()

    # _closeAll: (target) ->
    #   $('[data-dropdown],[data-dropdown-panel]').removeClass 'is-open'
    #   $('body').off 'click.me.dropdown'

  #------------------------#
  # Remove errors messages #
  #------------------------#
  removeFieldWithError:
    _selector: '[data-error-message], [data-field=with-error] input, [data-field=with-error] select, [data-field=with-error] textarea'

    init: ->
      $(document).on 'change', @_selector, (e) => @_remove($(e.target))

    removeAll: ($container = null) ->
      $nodes = if $container == null then $(@_selector) else $(@_selector, $($container))
      $nodes.each (i, elmt) =>
        @_remove $(elmt)

    _remove: ($target) ->
      _id = if $target.data('error-message') then $target.data('error-message') else $target.attr('id')

      # Replace error node with its children node
      while $target.closest('.field_with_errors').length > 0
        $_error_node = $target.closest('.field_with_errors')
        $_children_node = $_error_node.children()

        $_error_node.replaceWith $_children_node

      if _id
        $('[data-field-error="' + _id + '"]').remove() if $('[data-field-error="' + _id + '"]').length > 0

  #----------------------#
  # Auto height textarea #
  #----------------------#
  # textareaAutoHeight:
  #   _selector: '[data-behavior=textarea-auto-height]'

  #   init: ->
  #     $(document).on 'focus', @_selector, (e) ->
  #       $(e.currentTarget).attr('rows', 2)

  #       $(e.currentTarget).off('input keyup').on 'input keyup', (e) ->
  #         $textarea = $(e.currentTarget).get 0

  #         if $textarea.clientHeight < $textarea.scrollHeight
  #           $(e.currentTarget).css 'height', "#{$textarea.scrollHeight}px"

  #           if $textarea.clientHeight < $textarea.scrollHeight
  #             $(e.currentTarget).css 'height', "#{$textarea.scrollHeight * 2 - $textarea.clientHeight}px"

  #     $(document).on 'blur', @_selector, (e) ->
  #       if $(e.currentTarget).val() is ''
  #         $(e.currentTarget).attr('rows',  1).css 'height', 'auto'

  #------------------------------------#
  # Auto submit on <enter> on textarea #
  #------------------------------------#
  # textareaSubmitOnEnter:
  #   _selector: '[data-submit-on-enter]'

  #   init: ->
  #     $(document).on 'keypress', @_selector, (e) ->
  #       if e.which is 13
  #         e.preventDefault()
  #         e.stopImmediatePropagation()
  #         Rails.fire $(e.target).blur().closest('form').get(0), 'submit'

  #------------------------#
  # OpenMap route          #
  #------------------------#
  # route: ($canvas, coords, buttons = null) ->
  #   _route = $canvas.openMap(center:
  #     lat: coords.from.lat
  #     lng: coords.from.lng).show().direction coords

  #   _route.buttons buttons if buttons?

  #   return _route

  #------------------------------------#
  # Google places address autocomplete #
  #------------------------------------#
  address_autocomplete: ->
    autocompleteNode = $('[data-field="location"]')
    formSubmit       = autocompleteNode.data('submit') || false
    autocompleteNode.keydown (e) ->
      if e.keyCode == 13
        e.preventDefault()
        $(e.target).val('').blur()
    autocompleteNode.change ->
      if autocompleteNode.val() is ''
        $('[data-field="lat"]').val ''
        $('[data-field="lng"]').val ''
    autocomplete = new google.maps.places.Autocomplete autocompleteNode.get(0), { componentRestrictions: { country:  ['fr', 'be', 'ch', 'mc', 'lu'] } }
    google.maps.event.addListener autocomplete, 'place_changed', ->
      place = autocomplete.getPlace()
      return if !place.geometry

      $('[data-field="lat"]').val place.geometry.location.lat()
      $('[data-field="lng"]').val place.geometry.location.lng()
      $('[data-field="lnglat_rand"]').val place['types']? && place['types'].indexOf('locality') > -1
      autocompleteNode.parents('form').submit() if formSubmit
}

#------------------------#
# Document events        #
#------------------------#
# $(document).on 'submit', 'form[method=get]:not([data-remote])', (e) ->
#   e.preventDefault()
#   Turbo.visit(@action + (if @action.indexOf('?') == -1 then '?' else '&') + $(@).serialize())

# $(document).on 'ajax:beforeSend', 'form[data-multipart][data-me-progress]', (e) ->
#   if $(':file', $(e.target)).val() != ''
#     if $(':file', $(e.target)).get(0).files[0].size <= 367001600
#       $(e.target).on 'me:upload:progress', (e, loaded, total) ->
#         percents = Math.floor((loaded / total) * 1000)/10

#         if $.browser.mobile && !$('body').hasClass('tablet')
#           $progress_bar.val percents
#         else
#           $progress_bar.css 'width', "#{percents}%"

#         $progress_percent.html "#{percents}%"

#         return

#       if $.browser.mobile && !$('body').hasClass('tablet')
#         content = $('[data-me=upload-progress]').detach().first().html()
#         (new $.me.Modal 'content', {content: content}).show()

#         $progress_bar     = $('[data-me=progress] progress')
#         $progress_percent = $('[data-me=progress] strong')
#       else
#         $('[data-me=upload-progress] div').css 'width', 0
#         $('[data-me=upload-progress]').show()

#         $progress_bar     = $('[data-me=upload-progress] div')
#         $progress_percent = $('[data-me=upload-progress] span')

#     else
#       $.me.alert I18n.t('js.common.title_alert'), I18n.t('js.file_max_size', mb_size: 350)
#       return false

#   return true

# $(document).on 'me:layoutLoaded', ->
#   $.me.init 'store', 'config', 'notifications', 'trophy', 'sentences'

# Active for Route
# $(document).on 'turbo:load',  ->
#   controller = document.body.dataset.controller
#   action = document.body.dataset.action
#   document.querySelectorAll('[data-active-for-route]').forEach (e) ->
#     e.classList.remove('active')
#     routes = e.dataset.activeForRoute.split(',')
#     routes.forEach (r) ->
#       [c, a] = r.split('#')
#       e.classList.add('active') if c is controller && (!a? || a is action)

# $(document).on 'turbo:frame-render', (event) ->
#   headers = event.detail.fetchResponse.response.headers
#   $.me.actionCallback headers.get('x-controller'), headers.get('x-action')

#------------------------#
# On DOM Loaded          #
#------------------------#
$ ->
  $.me.init 'emoji', 'removeFieldWithError'
  #$.me.init 'dropdown', 'emoji', 'fieldCharCounter', 'sticker', 'removeFieldWithError', 'multipart', 'textareaAutoHeight', 'textareaSubmitOnEnter'
  # $(document).trigger 'me:domChanged', $(document)

  # $(document).on 'ajax:error', (e, xhr) ->
  #   [data, status, xhr] = e.detail if e.detail
  #   if xhr.status is 402
  #     $.me.restricted xhr
  #   else if xhr.status is 423
  #     $.me.privated xhr

  # # Remove selector
  # $(document).on 'click', '[data-remove-block]', (e) ->
  #   e.preventDefault()
  #   $($(e.currentTarget).data('remove-block')).remove()

  # # Dirty fix to keep scroll positions
  # $.me.scrollPositions = {
  #   online: 0,
  #   chat: 0
  # }

  # TODO : Check what is that shit for
  # $(document).on 'turbo:before-visit', ->
  #   $.me.scrollPositions.online = $('#online-users-container').scrollTop()
  #   $.me.scrollPositions.chat = $('#nlv2ChatScrollBox').scrollTop()
  # $(document).on 'turbo:load',  ->
  #   $('#online-users-container').scrollTop($.me.scrollPositions.online)
  #   $('#nlv2ChatScrollBox').scrollTop($.me.scrollPositions.chat)

#---------#
# Global  #
#---------#

# Subscriber restriction modal
# $.me.restricted = (xhr) ->
#   lscope   = "js.restrict.actions.#{xhr.getResponseHeader('X-Controller')}.#{xhr.getResponseHeader('X-Action')}"
#   title    = I18n.t('js.restrict.title')
#   message  = I18n.t('js.restrict.message')
#   redirect = '/account/subscriptions'
#   $.me.alert title, message, ->
#     $.me.chat.hide()
#     if window.ReactNativeWebView
#       window.location.href = redirect
#     else
#       Turbo.visit redirect, { action: xhr.getResponseHeader('Turbo-Action') }

# # Private restriction modal
# $.me.privated = (delegate) ->
#   xhr = delegate.xhr or delegate
#   lscope  = "js.private.actions.#{xhr.getResponseHeader('X-Controller')}.#{xhr.getResponseHeader('X-Action')}"
#   title   = I18n.t("#{lscope}.title", { defaultValue: I18n.t('js.private.title') })
#   message = I18n.t("#{lscope}.message", { defaultValue: I18n.t('js.private.message') })
#   $.me.alert title, message, ->
#     Turbo.visit(delegate.referrer, { action: 'replace' }) if delegate.referrer

# Monkey patch Turbolinks to render 404 & 500 normally and to manage special 4xx
# Turbolinks.HttpRequest.prototype.requestLoaded = ->
  # @endRequest =>
  #   if 200 <= @xhr.status < 300 or @xhr.status is 404 or @xhr.status is 500
  #     @delegate.requestCompletedWithResponse(@xhr.responseText, @xhr.getResponseHeader('Turbolinks-Location'))
  #   else if @xhr.status is 402
  #     $.me.restricted @xhr
  #   else if @xhr.status is 423
  #     $.me.privated @
  #   else
  #     @failed = true
  #     @delegate.requestFailedWithStatusCode(@xhr.status, @xhr.responseText)

# history.scrollRestoration = 'manual' if history.scrollRestoration
